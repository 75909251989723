import cn from 'classnames'
import Image from 'next/image'
import React from 'react'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'
import { getFullName } from 'src/utils/getFullName'
import { getInitials } from 'src/utils/getInitials'

interface Props {
  /**
   * Size(width/height) of the avatar - i.e. 12px, 1.2rem
   */
  size: string

  /**
   * font-size of the initials text - i.e. 12px, 1.2rem
   */
  initialsTextSize?: string

  /**
   * Avatar url
   */
  url?: string | null

  /**
   * Name for this Avatar
   * This will be used as "alt" prop of img.
   * Also, used to generate initials.
   */
  name: string | { firstName?: string | null; lastName?: string | null }

  /**
   * When avatar url is not defined,
   *
   * - "hidden" - Never render this avatar.
   * - "lth-logo" - Renders default logo.
   * - "initials" - Renders initials.
   */
  defaultMode?: 'hidden' | 'lth-logo' | 'initials'

  /**
   * Border type
   *
   * - "circle": border-radius: 50%
   * - "rounded": border-radius: 4px
   */
  border: 'circle' | 'rounded' | 'no-border'

  /**
   * Variant
   *
   * - "navy": background-navy text-white
   * - "navybright": background-navybright text-white
   */
  variant: 'navy' | 'navybright' | 'turquoise' | 'transparent'

  className?: string
}

export const Avatar: React.FC<Props> = ({
  className = '',
  size,
  url,
  name,
  initialsTextSize = '12px',
  defaultMode = 'lth-logo',
  border = 'circle',
  variant = 'navy',
}) => {
  const logoUrl = useDefaultLogo(url)
  const initials = getInitials(name)
  const fullName = typeof name === 'string' ? name : getFullName([name?.firstName, name?.lastName])
  let variantClassName = ''
  if (variant === 'navy') variantClassName = 'bg-navy border-[1px] border-navy text-white'
  else if (variant === 'navybright') variantClassName = 'bg-navybright border-[1px] border-navybright text-white'
  else if (variant === 'turquoise')
    variantClassName =
      'bg-turquoise border-[1px] text-navy' + (border === 'no-border' ? ' border-turquoise' : ' border-navybright')
  else variantClassName = 'bg-transparent border-0 text-navy'

  if (defaultMode === 'hidden' && !url) {
    return null
  }

  return (
    <div
      className={cn('flex items-center justify-center overflow-hidden object-cover', variantClassName, className)}
      style={{
        width: size,
        height: size,
        borderRadius: border === 'circle' || border === 'no-border' ? '50%' : '4px',
        flex: '0 0 27px',
      }}
    >
      {defaultMode === 'lth-logo' && (
        <Image
          src={logoUrl}
          alt={fullName}
          priority
          width={parseInt(size)}
          height={parseInt(size)}
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
        />
      )}
      {defaultMode === 'initials' && url && (
        <Image
          src={logoUrl}
          alt={fullName}
          priority
          width={parseInt(size)}
          height={parseInt(size)}
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
        />
      )}
      {defaultMode === 'initials' && !url && (
        <div className="font-secondary text-center" style={{ fontSize: initialsTextSize, alignContent: 'center' }}>
          {initials}
        </div>
      )}
    </div>
  )
}
