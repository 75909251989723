// import cn from 'classnames'
// import Link from 'next/link'
// import { cn } from '@fullcalendar/core/internal-common'
// import { url } from 'inspector'
import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
// import { relative } from 'path'
import React from 'react'

import { Avatar } from '../global/Avatar/Avatar'
// import { HtmlContentViewer } from '../global/HtmlContentViewer/HtmlContentViewer'
import { PremiumContentIcon } from '../global/PremiumContentIcon/PremiumContentIcon'
import { InsightCardTags } from './InsightCardTags'
// import styles from 'src/containers/Home/Home.module.scss'

// import { IconButton } from '../global/IconButton/IconButton'

//import { TrendingTopicCard, TrendingTopicCardProps } from 'src/components/TrendingTopicCard/TrendingTopicCard'
// import { IPremiumAudienceItemFragment, useTrendingContentsQuery } from 'src/graphql/generated/hooks'
// import { useAuthenticated } from 'src/hooks/useAuthenticated'

interface InsightCardsProps {
  className?: string
  insightItem: any
  key?: number
}

export const InsightCards: React.FC<InsightCardsProps> = ({ insightItem, key }) => {
  function stripHtmlTags(input: string) {
    return input.replace(/<[^>]*>/g, '')
    /* const parser = new DOMParser()
    const doc = parser.parseFromString(input, 'text/html')
    return doc.body.textContent || '' */
  }

  return (
    <>
      <div key={'insightCards-' + key} className="insight-cards rounded-lg p-[24px]">
        <Link href={`/contents/${insightItem?.slug}`} passHref>
          <div className="avtar-div  rounded-lg mb-[20px] relative">
            {insightItem?.defaultImage ? (
              <Image
                src={insightItem?.defaultImage}
                alt=""
                className="rounded-lg object-cover w-full"
                priority={true}
                width={100}
                height={100}
              />
            ) : (
              <PremiumContentIcon
                contentType={insightItem?.contentType}
                size="full"
                defaultImage={insightItem?.defaultImage}
              />
            )}
          </div>
          {insightItem?.title && (
            <h3 title={insightItem?.title} className="title line-clamp-2 mb-[8px]">
              {insightItem?.title}
            </h3>
          )}
        </Link>
        <div className="flex flex-wrap gap-2 mb-[8px]">
          {insightItem?.subjectMatters && <InsightCardTags tagItems={insightItem?.subjectMatters} />}

          {/* <span className="px-2 py-1 bg-green-100 text-green-600 text-xs font-medium rounded">GenAI</span>
        <span className="px-2 py-1 bg-blue-100 text-blue-600 text-xs font-medium rounded">ML/AI</span>
        <span className="px-2 py-1 bg-purple-100 text-purple-600 text-xs font-medium rounded">Future</span> */}
        </div>
        <Link href={`/contents/${insightItem?.slug}`} passHref>
          {insightItem?.writer?.name && (
            <div className="text-sm mb-2 font-semibold text-gray-800 flex items-center space-x-2">
              <Avatar
                size="27px"
                initialsTextSize="12px"
                url={insightItem?.writer?.avatar}
                name={insightItem?.writer?.name}
                defaultMode="initials"
                border={insightItem?.writer?.avatar ? 'circle' : 'no-border'}
                variant="turquoise"
              />

              <span className="writer-name">{insightItem?.writer?.name}</span>
            </div>
          )}
          {insightItem?.publishedTime && (
            <p className="published mb-2">Published {moment(insightItem?.publishedTime).format('YYYY-MM-DD')}</p>
          )}
          {insightItem?.snippet && (
            <p className="description line-clamp-3 overflow-hidden">{stripHtmlTags(insightItem?.snippet)}</p>
          )}
          {/* {insightItem?.snippet && (
          <div className="flex flex-col h-[10rem] mb-[0.3rem]">
            <div className="h-[4.8rem] mt-[.7rem] overflow-hidden">
              <HtmlContentViewer
                html={insightItem?.snippet || ''}
                contentClassName="header-des-html text-[1rem] font-primary truncate"
              />
            </div>
          </div>
        )} */}
        </Link>
      </div>
    </>
  )
}
