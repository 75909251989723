import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Image from 'next/image'
import React from 'react'
import { IEnum_Content_Contenttype } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'

import articleIcon from './assets/article.svg'
import businessCaseIcon from './assets/business_case.svg'
import competitiveAnalysisIcon from './assets/competitive_analysis.svg'
import evaluationFrameworkIcon from './assets/evaluation_framework.svg'
import vendorAnalysisSideIcon from './assets/icon_vendor_analysis.svg'
import landscapeArticleIcon from './assets/landscape_article.svg'
import pilotGuideIcon from './assets/pilot_guide.svg'
import requirementsTemplateIcon from './assets/requirements_template.svg'
import trainingIcon from './assets/training.svg'
//import vendorContentIcon from './assets/vendor_content.svg'

interface Props {
  contentType: IEnum_Content_Contenttype
  defaultImage?: string | null
  size: 'md' | 'lg' | '50' | 'sm' | 'full'
}

const svgIconSize = (size: 'md' | 'lg' | '50' | 'sm' | 'full') => {
  if (size === 'md') {
    return 40
  } else if (size === 'sm') {
    return 25
  } else {
    return 50
  }
}
const svgIconClass = (size: 'sm' | 'md' | 'lg' | '50' | 'full') => {
  if (size === 'sm') {
    return 'w-[25px] h-[25px] min-w-[25px] min-h-[25px]'
  } else if (size === 'md') {
    return 'w-[40px] h-[40px] min-w-[40px] min-h-[40px]'
  } else if (size === '50') {
    return 'w-[50px] h-[50px] min-w-[50px] min-h-[50px]'
  } else if (size === 'full') {
    return 'w-full h-full'
  }
  return 'w-[80px] h-[80px] min-w-[80px] min-h-[80px]'
}

const ioniconClass = (size: 'sm' | 'md' | 'lg' | '50' | 'full') => {
  if (size === 'sm') {
    return 'text-[25px]'
  } else if (size === 'md') {
    return 'text-[30px]'
  } else if (size === '50') {
    return 'text-[40px]'
  }
  return 'text-[70px]'
}

export const PremiumContentIcon: React.FC<Props> = ({ contentType, defaultImage, size }) => {
  const contentTypeWithoutSpace = contentType.replace(/ /g, '_')

  let icon

  if (contentTypeWithoutSpace === IEnum_Content_Contenttype.LandscapeArticle) {
    icon = landscapeArticleIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.EvaluationFramework) {
    icon = evaluationFrameworkIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.RequirementsTemplate) {
    icon = requirementsTemplateIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.BusinessCase) {
    icon = businessCaseIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.CompetitiveAnalysis) {
    icon = competitiveAnalysisIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.PilotGuide) {
    icon = pilotGuideIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.ThoughtLeadership) {
    icon = trainingIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.Blog) {
    icon = articleIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.VendorResource) {
    icon = articleIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.IndustryAnalysis) {
    icon = articleIcon
  } else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.BuyersGuide) {
    icon = landscapeArticleIcon
  }
  /*  else if (contentTypeWithoutSpace === IEnum_Content_Contenttype.VendorContent) {
    icon = vendorContentIcon
  } */

  if (icon) {
    return (
      <div className={cn('bg-clouds rounded-[5px] items-center justify-center flex', svgIconClass(size))}>
        <Image
          src={icon}
          className={cn(svgIconClass(size))}
          width={svgIconSize(size)}
          height={svgIconSize(size)}
          alt=""
        />
      </div>
    )
  }
  if (contentTypeWithoutSpace.startsWith(IEnum_Content_Contenttype.KnowHow)) {
    const [, image] = contentType.split('$$$')
    const logo = image || defaultImage
    if (logo) {
      return (
        <div className={cn('bg-clouds rounded-[5px] flex items-center justify-center', svgIconClass(size))}>
          <Image
            src={logo}
            alt={IEnum_Content_Contenttype.KnowHow}
            width={svgIconSize(size)}
            height={svgIconSize(size)}
          />
        </div>
      )
    } else {
      return (
        <div className={cn('bg-clouds rounded-[5px] flex items-center justify-center', svgIconClass(size))}>
          <IonIcon name="information" className={cn(ioniconClass(size), 'text-navybright')} />
        </div>
      )
    }
  } else if (contentTypeWithoutSpace.startsWith(IEnum_Content_Contenttype.LegaltechResourcesForLawyers)) {
    const [, image] = contentType.split('$$$')
    const logo = image || defaultImage
    if (logo) {
      return (
        <div className={cn('bg-clouds rounded-[5px] flex items-center justify-center', svgIconClass(size))}>
          <Image
            src={logo}
            alt={IEnum_Content_Contenttype.KnowHow}
            width={svgIconSize(size)}
            height={svgIconSize(size)}
            priority
          />
        </div>
      )
    } else {
      return (
        <div className={cn('bg-clouds rounded-[5px] flex items-center justify-center', svgIconClass(size))}>
          <IonIcon name="person-circle-outline" className={cn(ioniconClass(size), 'text-navybright')} />
        </div>
      )
    }
  } else if (contentTypeWithoutSpace.startsWith(IEnum_Content_Contenttype.VendorContent)) {
    const [, image] = contentType.split('$$$')
    const logo = image || defaultImage
    if (logo) {
      return (
        <div className={cn('bg-clouds rounded-[5px] flex items-center justify-center', svgIconClass(size))}>
          <Image
            src={logo}
            alt={IEnum_Content_Contenttype.KnowHow}
            width={svgIconSize(size)}
            height={svgIconSize(size)}
            priority
          />
        </div>
      )
    } else {
      return (
        <div className={cn('bg-clouds rounded-[5px] flex items-center justify-center', svgIconClass(size))}>
          <IonIcon name="person-circle-outline" className={cn(ioniconClass(size), 'text-navybright')} />
        </div>
      )
    }
  }
  if (contentTypeWithoutSpace.startsWith(IEnum_Content_Contenttype.VendorAnalysis)) {
    const [, vendorLogo] = contentType.split('$$$')
    const logo = useDefaultLogo(vendorLogo || defaultImage)
    return (
      <div className={cn('bg-clouds rounded-[5px] relative flex items-center justify-center', svgIconClass(size))}>
        <div
          className={cn(
            size === 'md' ? 'w-[25px] h-[25px] p-[2px]' : 'w-[35px] h-[35px] p-[3px]',
            'bg-white rounded-[5px]',
          )}
        >
          <Image
            src={logo}
            alt={IEnum_Content_Contenttype.VendorAnalysis}
            width={size === 'md' ? 25 : 35}
            height={size === 'md' ? 25 : 35}
            priority
          />
        </div>
        <div className={cn('absolute', size === 'md' ? 'bottom-0 right-[3px]' : 'bottom-[12px] right-[12px]')}>
          {size === 'md' && <Image src={vendorAnalysisSideIcon} width={10} height={16} alt="" />}
          {size === 'lg' && <Image src={vendorAnalysisSideIcon} width={16} height={20} alt="" />}
        </div>
      </div>
    )
  }
  return null
}
