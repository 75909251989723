import cn from 'classnames'
import Link from 'next/link'
import React from 'react'
import styles from 'src/containers/Home/Home.module.scss'

import { IconButton } from '../../components/global/IconButton/IconButton'
import { InsightCards } from './InsightCards'

//import { TrendingTopicCard, TrendingTopicCardProps } from 'src/components/TrendingTopicCard/TrendingTopicCard'
// import { IPremiumAudienceItemFragment, useTrendingContentsQuery } from 'src/graphql/generated/hooks'
// import { useAuthenticated } from 'src/hooks/useAuthenticated'

interface InsightsProps {
  className?: string
  trendingContents: any[]
}

export const Insights: React.FC<InsightsProps> = ({ trendingContents }) => {
  return (
    <div className={'flex flex-row justify-center bg-white'}>
      <div className="!max-w-[1080px] w-full flex flex-row items-center py-2">
        <div className="mx-auto">
          {/*     <!-- Section Header --> */}
          <div className={cn(styles.homeBuyPremiumBadge)}></div>
          <div className="text-left">
            <h2 className="text-2xl font-semibold text-[#222222]">Insights</h2>
            <p className="text-gray-600 mt-2">
              Lorem ipsum dolor sit amet consectetur. Nam magna orci consectetur in feugiat est et odio lacus. Nunc eros
              quis nulla adipiscing sed.
            </p>
            {/* <button className="mt-4 px-6 py-2 bg-blue-700 text-white rounded-lg shadow hover:bg-blue-800">
              Subscribe to Insights
            </button> */}
            <Link href={'https://hub.legaltechnologyhub.com/subscribe'}>
              <IconButton type="primary" label="Subscribe to Insights" className="w-[11.625rem] h-[3.125rem] pt-5" />
            </Link>
          </div>

          {/* <!-- Insights Grid --> */}
          <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[30px]">
            {/* <!-- Card --> */}
            {trendingContents?.map((item: any, index: number) => <InsightCards key={index} insightItem={item} />)}
          </div>

          {/* <!-- View All Button --> */}
          <div className="mt-10 text-center">
            <Link href="/content-insights/" className="view-all hover:underline text-lg font-medium">
              View all
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
