import cn from 'classnames'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import React, { useDeferredValue, useEffect, useState } from 'react'
// import { Collections } from 'src/components/Collections/Collections'
//#import { ExpertsCarousel } from 'src/components/ExpertsCarousel/ExpertsCarousel'
import { Footer } from 'src/components/Footer/Footer'
//#import { PremiumModal } from 'src/components/global/PremiumModal'
import { ThemeOnly } from 'src/components/global/ThemeOnly'
import { Header } from 'src/components/Header/Header'
import { Hero } from 'src/components/Hero/Hero'
// import { PopularTopics } from 'src/components/Home/PopularTopics/PopularTopics'
// import { HomeTopics } from 'src/components/HomeTopics/HomeTopics'
import { IltaFooter } from 'src/components/IltaFooter/IltaFooter'
import { IltaSponsorshipLevels } from 'src/components/IltaSponsorshipLevels/IltaSponsorshipLevels'
import { Insights } from 'src/components/Insights/Insights'
import { LastFromVendorCheatsheet } from 'src/components/LastFromVendorCheatsheet/LastFromVendorCheatsheet'
import SkeletonLoading from 'src/components/SkeletonLoading/SkeletonLoading'
// import { PremiumContentCategories } from 'src/components/PremiumContentCategories/PremiumContentCategories'
// import { Testimonials } from 'src/components/Testimonials/Testimonials'
// import { TrendingTopics } from 'src/components/TrendingTopics/TrendingTopics'
import { THEME, Theme } from 'src/config/config'
import { apolloSdk } from 'src/graphql/apolloSdk'
import {
  // ICollection,
  IIltaSponsorshipItemFragment,
  IPopularTopic,
  IPremiumAudienceItemFragment,
  ISubTopicItemFragment,
  ITestimonialItemFragment,
  ITopicItemFragment,
} from 'src/graphql/generated/hooks'
//#import { VendorSection } from './VendorSection'
//import { useTrendingContentsByPremiumAudienceQuery } from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'
import { Meta } from 'src/layout/Meta'
import { Main } from 'src/templates/Main'

// import { BuyerSection } from './BuyerSection'
// import { HomePremiumSection } from './HomePremiumSection'
// import { PremiumCategories } from './PremiumCategories'
// import { VendorSection } from './VendorSection'

interface BasicHomePageProps {
  theme: Theme
}
interface MainHomePageProps extends BasicHomePageProps {
  theme: 'legaltech'
  audiences?: IPremiumAudienceItemFragment[]
  premiumCategories?: (ITopicItemFragment | ISubTopicItemFragment)[]
  topics: ITopicItemFragment[]
  testimonials: ITestimonialItemFragment[]
  popularTopics: IPopularTopic[]
  trendingContents: any[]
}

interface IltaHomePageProps extends BasicHomePageProps {
  theme: 'ilta'
  audiences?: IPremiumAudienceItemFragment[]
  topics: ITopicItemFragment[]
  iltaSponsorships: IIltaSponsorshipItemFragment[]
  trendingContents: any[]
}

export type HomePageProps = MainHomePageProps | IltaHomePageProps

const isMainHomePageProps = (props: HomePageProps): props is MainHomePageProps => props.theme === 'legaltech'
const isIltaHomePageProps = (props: HomePageProps): props is IltaHomePageProps => props.theme === 'ilta'

// dynamic component imports
const ExpertsCarousel = dynamic(
  () => import('src/components/ExpertsCarousel/ExpertsCarousel').then((mod) => mod.ExpertsCarousel),
  {
    ssr: false,
    loading: () => <SkeletonLoading className="bg-navy" />,
  },
)
const PremiumModal = dynamic(() => import('src/components/global/PremiumModal').then((mod) => mod.PremiumModal), {
  ssr: false,
  loading: () => <SkeletonLoading className="bg-navy" />,
})
const TrendingTopics = dynamic(
  () => import('src/components/TrendingTopics/TrendingTopics').then((mod) => mod.TrendingTopics),
  {
    ssr: false,
    loading: () => <SkeletonLoading className="bg-navy" />,
  },
)
const HomeTopics = dynamic(() => import('src/components/HomeTopics/HomeTopics').then((mod) => mod.HomeTopics), {
  ssr: false,
  loading: () => <SkeletonLoading className="bg-clouds" />,
})
const HomePremiumSection = dynamic(() => import('./HomePremiumSection').then((mod) => mod.HomePremiumSection), {
  ssr: false,
  loading: () => <SkeletonLoading className="bg-white" />,
})
const VendorSection = dynamic(() => import('./VendorSection').then((mod) => mod.VendorSection), {
  ssr: false,
  loading: () => <SkeletonLoading className="bg-white" />,
})
const PopularTopics = dynamic(
  () => import('src/components/Home/PopularTopics/PopularTopics').then((mod) => mod.PopularTopics),
  {
    ssr: false,
    loading: () => <SkeletonLoading className="bg-clouds" />,
  },
)
const Testimonials = dynamic(() => import('src/components/Testimonials/Testimonials').then((mod) => mod.Testimonials), {
  ssr: false,
  loading: () => <SkeletonLoading className="bg-white" />,
})
const PremiumContentCategories = dynamic(
  () =>
    import('src/components/PremiumContentCategories/PremiumContentCategories').then(
      (mod) => mod.PremiumContentCategories,
    ),
  {
    ssr: false,
    loading: () => <SkeletonLoading className="bg-navy" />,
  },
)
const PremiumCategories = dynamic(() => import('./PremiumCategories').then((mod) => mod.PremiumCategories), {
  ssr: false,
  loading: () => <SkeletonLoading className="bg-navy" />,
})
const Collections = dynamic(() => import('src/components/Collections/Collections').then((mod) => mod.Collections), {
  ssr: false,
  loading: () => <SkeletonLoading className="bg-navy" />,
})

const Home: NextPage<HomePageProps> = (props) => {
  const { topics: topicsData } = props
  const { trendingContents: trendingContentsData } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [topics, setTopics] = useState(topicsData)
  const [isPremiumPreview, setIsPremiumPreview] = useState(false)
  const [isPremiumModalVisible, setIsPremiumModalVisible] = useState(false)
  const { isPremium, isVendor, status } = useAuthenticated()
  const title = 'Legaltech Hub'
  const description =
    'Legaltech Hub is the global directory of legal technology solutions, tools and services where buyers of legal technology can research the legal tech they need to manage a 21st century law firm or in-house department, vendors can showcase their solutions, and investors can understand the marketplace.'

  const meta = <Meta title={title} description={description} url="/" />
  const isPremiumMode = isPremium || isPremiumPreview
  const deferredIsPremiumPreview = useDeferredValue(isPremiumPreview)

  const [audiences, setAudiences] = useState([])

  // const [premiumTopics, setPremiumTopics] = useState([])
  // const [premiumSubTopics, setPremiumSubTopics] = useState([])
  const [premiumCategories, setPremiumCategories] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trendingContents, setTrendingContents] = useState(trendingContentsData)

  useEffect(() => {
    const topicData = (topics || []) as ITopicItemFragment[]
    localStorage.setItem('topics', JSON.stringify(topicData))
  }, [topics])

  useEffect(() => {
    if (status === 'authenticated') {
      const fetchAudiences = async () => {
        const audiencesData: any = await apolloSdk.premiumAudiences({
          filters: { shouldShowOnHomepage: { eq: true } },
          sort: ['name:desc'],
        })
        //console.log(audiencesData?.premiumAudiences?.data)
        setAudiences(audiencesData?.premiumAudiences?.data)
        const premiumTopicsData: any = await apolloSdk.premiumTopics()
        const premiumSubTopicsData: any = await apolloSdk.premiumSubTopics()

        // setPremiumTopics(premiumTopicsData?.topics?.data)
        // setPremiumSubTopics(premiumSubTopicsData?.subTopics?.data)
        const premiumCategoriesData: any[] = (premiumTopicsData?.topics?.data || []).concat(
          (premiumSubTopicsData?.subTopics?.data || []) as any,
        )
        setPremiumCategories(premiumCategoriesData)
      }
      fetchAudiences()
    }
  }, [status])

  return (
    <Main meta={meta}>
      <ThemeOnly theme="legaltech">
        <Header topicData={topics} key={'Header-1'} />
      </ThemeOnly>
      <PremiumModal
        key={'PM-1'}
        origin="/home/"
        isOpen={isPremiumModalVisible}
        onClose={() => setIsPremiumModalVisible(false)}
      />
      <Hero
        key={'Hero-1'}
        isPremiumPreview={deferredIsPremiumPreview}
        onPreviewPremium={() => setIsPremiumPreview(true)}
      />
      <h1 className="hidden">Legaltech Hub</h1>
      {!isPremiumMode && (
        <div>
          <div className="w-full px-3 large-lg:px-0 min-h-[28.75rem] bg-clouds pb-6">
            <div className="!max-w-[1080px] flex flex-col justify-center m-auto">
              <span
                className={cn('text-center mt-10 mb-6 font-bold text-[1.5rem] text-[#222222] font-secondary', {
                  '!text-[#3D4246] !font-primary': THEME === 'ilta',
                })}
              >
                Browse by Topic
              </span>
              <HomeTopics key={'HomeTopics-1'} className={cn({ '!text-textdark': THEME === 'ilta' })} topics={topics} />
            </div>
          </div>

          {isMainHomePageProps(props) && (
            <ThemeOnly theme="legaltech">
              {trendingContents && trendingContents?.length > 0 && (
                <Insights key={'Insights-1'} trendingContents={trendingContents} />
              )}
              <HomePremiumSection key={'HomePremiumSection-1'} />

              <VendorSection key={'VendorSection-1'} />
              <PopularTopics key={'PopularTopics-1'} topics={props.popularTopics} />

              <Testimonials key={'Testimonials-1'} testimonials={props.testimonials} />
            </ThemeOnly>
          )}
        </div>
      )}
      <ThemeOnly theme="legaltech">
        {isPremiumMode && isMainHomePageProps(props) && (
          <div key={'PremiumContent-1'}>
            <PremiumContentCategories key={'PremiumContentCategories-1'} isVendor={isVendor} />
            <PremiumCategories key={'PCategories-1'} premiumCategories={premiumCategories} topics={topics} />
          </div>
        )}
        {isVendor && isPremiumMode && <LastFromVendorCheatsheet key={'LastFromVendorCheatsheet-1'} />}

        {isPremiumMode && isMainHomePageProps(props) && (
          <div>
            {/* {SHOW_COLLECTION && <Collections />} */}
            <Collections key={'Collections-1'} />
            {audiences?.map((audience: any) => (
              <TrendingTopics
                key={audience.id!}
                audience={audience}
                onClickWhenDisabled={() => setIsPremiumModalVisible(true)}
              />
            ))}
            {/* {trendingContentsByPremiumAudience?.map((audience: any) => (
              <TrendingTopics
                key={audience.id!}
                audience={audience}
                onClickWhenDisabled={() => setIsPremiumModalVisible(true)}
              />
            ))} */}
            <ExpertsCarousel key={'ExpertsCarousel-1'} />
          </div>
        )}
      </ThemeOnly>

      <ThemeOnly theme="ilta">
        <div key={'Browse-by-sponsor-levels-1'} className="w-full px-3 large-lg:px-0 min-h-[28.75rem] bg-white pb-6">
          <div className="!max-w-[1100px] flex flex-col justify-center m-auto">
            <span className="text-center mt-10 mb-6 font-bold text-[2rem] text-textdark font-primary">
              Browse by sponsor levels
            </span>
            <span className="text-center mb-6 font-secondary text-[16px]">
              Search for ILTA business partners and consultants who offer products and services in the legal community.
            </span>
            <div className="mt-[20px] mb-[88px]">
              {isIltaHomePageProps(props) && (
                <IltaSponsorshipLevels key={'IltaSponsorshipLevels-1'} litaSponsorships={props.iltaSponsorships} />
              )}
            </div>
          </div>
        </div>
      </ThemeOnly>

      <ThemeOnly theme="legaltech">
        <Footer key={'Footer-1'} />
      </ThemeOnly>

      <ThemeOnly theme="ilta">
        <IltaFooter key={'IltaFooter-1'} />
      </ThemeOnly>
    </Main>
  )
}

export default Home
