import React, { useState } from 'react'

interface InsightCardTagsProps {
  className?: string
  tagItems?: any
}

export const InsightCardTags: React.FC<InsightCardTagsProps> = ({ tagItems }) => {
  // eslint-disable-next-line no-console
  const tagArray = tagItems.split(',').map((item: string) => item.trim())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tags, setTags] = useState<string[]>(tagArray)
  const [showTag, setShowTag] = useState<boolean>(false)

  return (
    <div>
      {/* {tags && tags.length > 0 && (
        <>
          <span className="badges">{tags[0]}</span>
          {tags.length > 1 && (
            <span
              className="px-2 py-1  text-blue-600 text-xs font-medium rounded"
              dangerouslySetInnerHTML={{
                __html: `+${tags.length - 1} more tags`,
              }}
            ></span>
          )}
        </>
      )} */}
      <div className={`badges_wrapper ${showTag ? 'show' : ''}`}>
        {tags.map((item, index) => (
          <span key={index} className="badges bg-gray-200 px-2 py-1 rounded-full text-sm ">
            {item}
          </span>
        ))}
        {tags.length > 1 && (
          <button className="px-2 py-1  text-blue-600 text-xs font-medium rounded" onClick={() => setShowTag(!showTag)}>
            {!showTag ? `+${tags.length - 1} more` : '- less'}
          </button>
        )}
      </div>
    </div>
  )
}
